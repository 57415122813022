import React from "react";
import Dashboard from "../components/dashboard/dashboard";


function DashboardPage(){
    return (
        <>
<Dashboard/>
        </>
    )
}
export default DashboardPage