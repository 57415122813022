import React from "react";
import Card from "./card";
// import card from '../assets/wht.jpeg';
import sponsor from '../assets/comment.json'
import hindsightventureslogo from '../assets/hindsightventureslogo.png';
import norsskenlogo from '../assets/norsskenlogo.png';
import womenInBusinesslogo from '../assets/womenInBusinesslogo.png';
import jasirilogo from '../assets/jasirilogo.png';
import africatechlogo from '../assets/africatechlogo.png';

function Comment() {
    return (
        <>
            <div className="protection">
                <div className="head">
                    <p>Join our community of parents and let your voice be heard! Share your valuable reviews
                        and experiences with us. Your feedback matters</p>
                </div>

                <div className="cards">
                    {sponsor ? (sponsor.map((sponsorx) => (
                        <Card key={sponsorx.name} desc={sponsorx.description} photo={sponsorx.newsImage} name={sponsorx.name} location={sponsorx.location} />
                    ))) : (
                        <p>Loading...</p>
                    )}
                </div>
                <div className="sponsor">
                    <h1>Our Amazing Partners</h1>
                    <div className="our">
                        <div className="partner">
                            <img src={hindsightventureslogo} alt="Hindsight Ventures" />
                        </div>
                        <div className="partner">
                            <img src={womenInBusinesslogo} alt="Women in Business" />
                        </div>
                        <div className="partner">
                            <img src={norsskenlogo} alt="Norsken" />
                        </div>
                        <div className="partner">
                            <img src={jasirilogo} alt="Jasiri" />
                        </div>
                        <div className="partner">
                            <img src={africatechlogo} alt="Africa Tech Startup Forum" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Comment;




