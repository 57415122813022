import React from "react";
import Home from "../components/home";
import CountDown from "../components/coutdown";
import HowItWorks from "../components/howitworks";
import Award from "../components/award";


const Homes=()=>{
    return(
        <>
        <Home/>
        <CountDown/>
        <HowItWorks/>
        <Award/>
        </>
    )
}
export default Homes